<template>
  <div class="contanier">
    <navigation msg="我的职业档案"></navigation>
    <div class="style">
      <div class="comment" @click="comment(0)">
        <div style="margin-top:0.15rem;padding-bottom:0.1rem;border-bottom:0.01rem solid #C1EDFF" class="flex7">
           <div class="pj">本公司</div>
           <div>
             <span class="count">{{theCompanyCount}}</span>
            <span style="font-size:0.3rem;">条</span>
            </div>
        </div>
        <div style="margin-top:0.15rem;padding-bottom:0.1rem; " class="flex7">
           <div class="pj">评论数</div>
           <div>
             <span class="count">{{counts1}}</span>
            <span style="font-size:0.3rem;">条</span>
            </div>
        </div>

       
        <!-- <div class="txt">
          <span class="count">{{theCompanyCount}}</span>
          条
        </div> -->
      </div>
      <div class="comment1" @click="comment(1)">
         <!-- <div class="pj">其他公司评价</div>
         <div class="txt"><span class="count">{{otherCompanyCount}}</span>条</div> -->
          <div style="margin-top:0.15rem;padding-bottom:0.1rem;border-bottom:0.01rem solid #FFD4B7" class="flex7">
           <div class="pj">其他企业</div>
           <div>
             <span class="count">{{otherCompanyCount}}</span>
            <span style="font-size:0.3rem;">条</span>
            </div>
        </div>
        <div style="margin-top:0.15rem;padding-bottom:0.1rem; " class="flex7">
           <div class="pj">评论数</div>
           <div>
             <span class="count">{{counts2}}</span>
            <span style="font-size:0.3rem;">条</span>
            </div>
        </div>
      </div>
    </div>

   
      <div v-if="showcomment" class="commentList">
         <div v-if="myCompanyList.length>0">
          <div class="commentItem"  v-for="(item,index) in myCompanyList" :key="index">
            <div class="title " style="display:flex;justify-content: space-between;">
              <div style="width: 5.5rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">评价公司：{{myCompanyName}}</div> 
              <div class="btn" @click="commentInfo(index)">{{item.txt}}</div>
            </div>
            <div v-if="item.isShow">
              <!-- 基本信息 -->
              <div class="baseInfo">
                <div class="info1">
                  评价人： <span class="text">{{item.username}}</span>
                </div>
                <div class="info1">评价人职务：{{item.positions}}</div>
                <!-- <div class="info1">被评价人：{{item.staff_name}}</div> -->
                <div class="info1">被评价人职位：{{item.position}}</div>
                <div class="info1">创建时间：{{item.add_time}}</div>
                <div class="info1">评价标签：工作表现</div>
                <div class="info1" style="display:flex">
                  <div style="width:1.5rem;">评价文字：</div> 
                  <div style="width:4rem;">{{item.is_open == 1 ? item.content : "**********"}}</div> 
                </div>
              </div>
              <!-- 评论 回评 -->
              <div class="bottom">
                <div class="flex" @click="goCommentDetail(item.id)">
                  <img class="recommentImg" src="../../assets/recomment.png" alt="">
                  <span class="count1">{{item.count}}</span>
                </div>
                <div class="recomment" @click ="goRecomment(item.id)">我要回评</div>
              </div>
            </div>
          </div>
        </div>
        <div class="nomsg" v-else>
          <img src="../../assets/recomment1.png" alt="">
          <div>- 您还没有任何评价 -</div>
        </div>
      </div>
      <div v-else>
        <div v-if="otherCompanyList.length>0">
          <div v-for="(item,index) in otherCompanyList" :key="index">
            <div class="title1">
                <div style="width: 5.5rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">评价公司：{{item.company}}</div> 
                <div class="btn" @click="otherIsShow(index)">{{item.txt}}</div>
            </div>
            <div class="commentlist" v-if="item.isShow == true">
              <div  class="commentItem"  v-for="(item1,index1) in item.commentList" :key="index1">
                <div class="baseInfo">
                  <div class="info1">
                    评价人： <span class="text">{{item1.username}}</span>   
                  </div>
                  <div class="info1">评价人职务：{{item1.positions}}</div>
                  <div class="info1">被评价人职位：{{item1.position}}</div>
                  <div class="info1">创建时间：{{item1.add_time}}</div>
                  <div class="info1">评价标签：工作表现</div>
                  <div class="info1" style="display:flex">
                    <div style="width:1.5rem;">评价文字：</div> 
                    <div style="width:4rem;">{{item1.is_open == 1 ? item1.content : "**********"}}</div> 
                  </div>
                </div>
              
                <div class="bottom">
                  <div class="flex" @click="goCommentDetail(item1.id)">
                    <img class="recommentImg" src="../../assets/recomment.png" alt="">
                    <span class="count1">{{item1.count}}</span>
                  </div>
                  <div class="recomment" @click="goRecomment(item1.id)">我要回评</div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="nomsg" v-else>
          <img src="../../assets/recomment1.png" alt="">
          <div>- 您还没有任何评价 -</div>
        </div>
      </div>
   
    
  </div>
</template>
<script>
import navigation from "../../components/navigation/index"
import { Toast } from "vant";
export default {
  data(){
    return {
      showcomment:true,  //控制本公司评价及其他公司评价的显示隐藏
      otherCompanyCount:"", //其他公司评价数
      theCompanyCount:"",  //本公司评价数
      otherCompanyList:[],  //其他公司评价列表
      myCompanyList:[],     //本公司评价列表
      myCompanyName:"",      //本公司名称
      counts1:"",
      counts2:""
    }
  },
  components: {
    navigation
  },

  mounted(){
    sessionStorage.removeItem("myCompanyList")
    sessionStorage.removeItem("otherCompanyList")
    this.getMsg()
  },

  methods:{
    getMsg(){
      this.$http.post("/user/v1/pro_files/myProFiles",{reqType:"myProFiles"})
    .then((res)=>{
      let response = JSON.parse(res.data)
       console.log(response)
      if(response.code == 0){
        //其他公司评价条数
        this.otherCompanyCount = response.data.otherCompanyCount
        //本公司评价条数
        this.theCompanyCount = response.data.theCompanyCount
        //本公司名称
        this.myCompanyName = response.data.myCompanyList.company
        //其他公司评价列表
        let otherCompanyList =  response.data.companyList
        //本公司评价列表
        let myCompanyList  =  response.data.myCompanyList.commentList
       
        console.log(otherCompanyList)

        if(otherCompanyList.length>0){
          otherCompanyList.map((value)=>{
              value.isShow = false
              value.txt = "展开"
              return value
            
          })
        }
        
        if(sessionStorage.getItem("otherCompanyList") == null){
          if(otherCompanyList.length > 0){
            otherCompanyList[0].isShow = true
             this.otherCompanyList = otherCompanyList
              let counts2 = 0;
              otherCompanyList.forEach(element => {
                element.commentList.forEach((value)=>{
                  counts2 +=  value.count
                })
              });
              this.counts2 = counts2
              console.log(counts2)
          }else{
            this.counts2 = 0
          }
         


            sessionStorage.setItem("otherCompanyList",JSON.stringify(otherCompanyList))
        }else{
           this.otherCompanyList = JSON.parse(sessionStorage.getItem("otherCompanyList"))
           console.log( this.otherCompanyList)
        }
        
        
     
        if(myCompanyList.length>0){
            let counts = 0
            myCompanyList.map((value)=>{
              counts += value.count
              value.isShow = false
              value.txt = "展开"
              return value
            })
            console.log(counts)
            this.counts1 = counts
        }else{
          this.counts1 = 0
        }
        console.log(myCompanyList)
        if(sessionStorage.getItem("myCompanyList") == null){
          if(myCompanyList.length>0){
            myCompanyList[0].isShow = true
          }
          this.myCompanyList = myCompanyList
          
          sessionStorage.setItem("myCompanyList",JSON.stringify(myCompanyList))
        }else{
           this.myCompanyList = JSON.parse(sessionStorage.getItem("myCompanyList"))
          
        }
        // console.log(this.otherCompanyList, this.myCompanyList)  
       
      }else{
        Toast.fail(response.msg)
      }
      
    })
    .catch((e)=>{console.log(e)})


    },
    goRecomment(id){
      // console.log(id,item)
     
      this.$router.push({path:"/recomment",query:{id:id}})
    },
    goCommentDetail(id){
      let companyName = this.myCompanyName
      this.$router.push({path:"/commentDetail",query:{id:id,companyName:companyName}})
    },
    commentInfo(index){
      let list1 = JSON.parse(sessionStorage.getItem("myCompanyList"))
      let list = list1[index]
      // console.log(list)
        if(list.isShow == true){
          list.isShow = false 
          list.txt = "展开"
          sessionStorage.setItem("myCompanyList",JSON.stringify(list1))
          this.myCompanyList = JSON.parse(sessionStorage.getItem("myCompanyList"))
          // console.log(list1)
        }else{
          list.isShow = true
          list.txt = "收起"
          for(var i =0;i<list1.length;i++){
            if(index != i){
              list1[i].isShow = false
                list1[i].txt = "展开"
            }
          }
        sessionStorage.setItem("myCompanyList",JSON.stringify(list1))
        this.myCompanyList = JSON.parse(sessionStorage.getItem("myCompanyList"))
         
        }
    },
    comment(type){
      if(type == 0){

        this.showcomment =  true
        if(JSON.parse(sessionStorage.getItem("myCompanyList")) !=null){
           this.myCompanyList = JSON.parse(sessionStorage.getItem("myCompanyList"))
        console.log(this.myCompanyList)
        }else{
           this.myCompanyList = []
        }
       
      }else{
        console.log(1)
         this.showcomment =  false
        // 查看其他公司默认展开第一条数据
        //  let otherlist =  this.otherCompanyList[0].commentList[0]
        //  otherlist.isShow = true
        //  otherlist.txt = "收起"
        if(JSON.parse(sessionStorage.getItem("otherCompanyList")) != null){
          this.otherCompanyList = JSON.parse(sessionStorage.getItem("otherCompanyList"))
        
         console.log(this.showcomment)
        }else{
          this.otherCompanyList = []
        }
         
      }
    },
    otherIsShow(index){
      // console.log(index)
      let list1 = JSON.parse(sessionStorage.getItem("otherCompanyList"))
      // console.log(list1)
      let list = list1[index]
      // console.log(list)
        if(list.isShow == true){
          list.isShow = false 
          list.txt = "展开"
          sessionStorage.setItem("otherCompanyList",JSON.stringify(list1))
          this.otherCompanyList = JSON.parse(sessionStorage.getItem("otherCompanyList"))
          // console.log(list1)
        }else{
          list.isShow = true
          list.txt = "收起"
          for(var i =0;i<list1.length;i++){
            if(index != i){
              list1[i].isShow = false
                list1[i].txt = "展开"
            }
          }
          // console.log(list)
        sessionStorage.setItem("otherCompanyList",JSON.stringify(list1))
        this.otherCompanyList = JSON.parse(sessionStorage.getItem("otherCompanyList"))
         
        }


  },
    
    
  }
}
</script>
<style  scoped>
.commentlist{
  height:7rem;
   overflow-y:auto;
}
.nomsg{
 text-align: center;
 margin-top:2.3rem;
}
.nomsg img{
  width:2rem;
  height:1.8rem;
}
.nomsg div{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #6F6F6F;
margin-top:0.2rem;
}
.flex{
  display: flex;
  align-items: center;
}
.recomment{
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FF4900;
}
.count1{
 font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #6F6F6F;
margin-left:0.1rem;
}
.recommentImg{
  width:0.4rem;
  height:0.4rem;
}
.bottom{
  height:0.85rem;
  border-top:0.02rem solid #ddd;
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:0 0.35rem;
}
.info1{
  padding:0.22rem 0
}
.text{
  color:#222222;
}
.baseInfo{
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #6F6F6F;
  padding:0.25rem;
}
.commentItem{
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.2);
  margin:0 0.42rem;
  margin-bottom:0.32rem;
}
 
.btn{
/* float:right; */
padding-right:0.25rem;
  font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FF5F19;
}
.title1{
  border-radius: 0.08rem;
  box-shadow: 0px 0  0.10rem 0  #C3F4FF;
   height: 0.85rem;
  background: #FFEDE4;
  line-height:0.85rem;
  padding-left:0.25rem;
  margin: 0 0.4rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-top:0.21rem;
  display:flex;
  justify-content: space-between;
}
.title{
  /* width: 6.7rem; */
  height: 0.85rem;
  background: #FFEDE4;
  line-height:0.85rem;
  padding-left:0.25rem;
  margin: 0 auto;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  margin-top:0.21rem;
}
.txt{
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
}
.count{
  font-size: 0.4rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FF5F19;
margin-left:0.43rem;
}
.comment{
 width: 2.8rem;
  height: 1.49rem;
  background: #EAF6FD;
  box-shadow: 0px 0  0.10rem 0  #C3F4FF;
  border-radius: 0.08rem;
  background-image:url('../../assets/comment.png') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding:0 0.2rem;
}
.comment1{
  width: 2.8rem;
  height: 1.49rem;
  background: #FFDEC7;
  box-shadow: 0px 0  0.10rem 0  #FFDEC7;
  border-radius: 0.08rem;
  background-image:url('../../assets/comment1.png') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding:0 0.2rem;
}
  .contanier{

  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.style{
  display:flex;
  justify-content: space-between;
  padding:0.38rem;
}
.pj{
  font-size: 0.28rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
/* margin-left:0.43rem;
/* margin-top:0.22rem; */


}
.flex7{
  display:flex;
  justify-content: space-between;
  align-items: center;
  
}

</style>